








import { Component, Vue } from 'vue-property-decorator';
import LinkList from './components/LinkList.vue';
import {mapActions} from "vuex";
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'

//
@Component({
  components: {
    LinkList,
  },
})
export default class App extends Vue {
  @Action loadLinks: any;

  created() {
    this.loadLinks();
  }
}
