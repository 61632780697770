



























import {Component, Prop, Vue} from 'vue-property-decorator';
import {State} from "vuex-class";

@Component
export default class LinkList extends Vue {
  @State links!: Array<Array<string>>;

  colors = [
    '#00AEEF',
    '#EC008C',
    '#7AC143',
    '#F47B20',
    '#2E3192',
  ]
}
